showCloseCourseBtn
<template>
  <view-base>
    <v-form :id="formId" v-model="formIsValid" ref="form" @submit.prevent="submitForm">
      <v-row class="d-flex flex-column">
        <v-col class="flex-shrink-1 flex-grow-0 prd-maxwidth-100">
          <the-tools-bar>
            <template #tools-left>
              <allowed-actions-btns
                :allowed-actions="allowedActions"
                @courseClosed="getTrainingCourseData"
                @courseActivated="getTrainingCourseData"
                @certificatesGenerated="getTrainingCourseData"
                :notApprovedStudents="formData.notApprovedStudents || null"
              />
            </template>

            <template #tools-right>
              <tool-bar-btns
                :formId="formId"
                :submit-loading="submitLoading"
                @closeItem="closeItem"
                @handleCloseAfterSubmit="handleCloseAfterSubmit($event)"
              />
            </template>
          </the-tools-bar>
        </v-col>

        <v-col v-if="isLoading" class="d-flex justify-center fill-height prd-maxwidth-100 pt-8">
          <v-progress-circular class="my-12" :size="50" :color="loadingColor" indeterminate />
        </v-col>

        <v-col v-else>
          <v-row>
            <v-col cols="12">
              <info-card :form-data="formData" />
            </v-col>
            <v-col cols="12" sm="4" lg="3">
              <course-data-card :form-data="formData" :course-status="courseStatus" />
            </v-col>
            <v-col cols="12" sm="8" lg="9">
              <v-row no-gutters>
                <v-col cols="12">
                  <client-data-card :form-data="formData"></client-data-card>
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12">
                  <localization-data-card :form-data="formData"></localization-data-card>
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12">
                  <third-party-data-card :form-data="formData"></third-party-data-card>
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12">
                  <comments-card :form-data="formData"></comments-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </view-base>
</template>

<script>
import crudMdEditMixin from "@/mixins/crudMdEditMixin";

export default {
  mixins: [crudMdEditMixin],
  components: {
    infoCard: () => {
      return import("@/modules/cardioguard-admin/components/views/training-courses-edit/InfoCard");
    },
    courseDataCard: () => {
      return import(
        "@/modules/cardioguard-admin/components/views/training-courses-edit/CourseDataCard"
      );
    },
    localizationDataCard: () => {
      return import(
        "@/modules/cardioguard-admin/components/views/training-courses-edit/LocalizationDataCard"
      );
    },
    clientDataCard: () => {
      return import(
        "@/modules/cardioguard-admin/components/views/training-courses-edit/ClientDataCard"
      );
    },
    thirdPartyDataCard: () => {
      return import(
        "@/modules/cardioguard-admin/components/views/training-courses-edit/ThirdPartyData"
      );
    },
    commentsCard: () => {
      return import(
        "@/modules/cardioguard-admin/components/views/training-courses-edit/CommentsCard"
      );
    },
    allowedActionsBtns: () => {
      return import(
        "@/modules/cardioguard-admin/components/views/training-courses-edit/allowedActionsBtns"
      );
    },
    ViewBase: () => import("@/layouts/intranet-tabs/ViewBase"),
    TheToolsBar: () => import("@/components/TheToolsBar.vue"),
    ToolBarBtns: () => import("@/components/crud-md/actions/EditToolBarBtns"),
  },
  data: () => ({
    formIsValid: null,
    formData: {},
    courseStatus: null,
  }),
  beforeMount() {
    this.setLoadingColor();
    this.currentService = this.$store.state.currentService;
    this.itemId = this.$route.params["id"];
    this.itemTitle = "#" + this.itemId;
    this.updateViewTitle();
  },
  async mounted() {
    await this.getTrainingCourseData();
    this.itemTitle = `#${this.$route.params.id} - ${this.formData.code}`;
    await this.updateViewTitle();
  },
  methods: {
    async getTrainingCourseData() {
      try {
        this.isLoading = true;
        const { data: courseData } = await this.currentService.getTrainingCourseData(
          this.$route.params.id
        );
        for (const responseKey in courseData) {
          this.$set(this.formData, responseKey, courseData[responseKey]);
        }
        this.courseStatus = courseData.status;
      } catch (e) {
        this.$notifyError(undefined, "Error al descargar los datos del curso | " + e);
      } finally {
        this.isLoading = false;
      }
    },
    async submitForm() {
      this.$refs.form.validate();
      if (this.formIsValid) {
        try {
          this.submitLoading = true;
          const response = await this.currentService.updateTrainingCourseData(
            this.itemId,
            this.formData
          );
          const { errorMessage } = response.data;

          if (errorMessage) {
            this.$notifyError("Datos incorrectos", errorMessage);
          } else {
            this.$notifySuccess();
            if (this.closeAfterSubmit) this.closeItem();
          }
        } catch {
          this.$notifyError();
        } finally {
          this.submitLoading = false;
        }
      }
    },
  },
  computed: {
    allowedActions() {
      return this.formData.allowedActions || {};
    },
  },
};
</script>

<style scoped></style>
